import * as React from "react";
import Loader from "react-loader";
import "./styles.scss";

interface ISubmitButtonProps {
  title: string;
  action: Function;
  loading: boolean;
  cssClass: string;
  disabled: boolean;
}

const SubmitButton: React.FunctionComponent<ISubmitButtonProps> = ({
  loading,
  action,
  title,
  cssClass,
  disabled,
}) => {
  return (
    <button
      type="submit"
      className={`submit ${cssClass}`}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        if (action) action();
      }}
    >
      {title} <Loader loaded={!loading} color="#fff" scale={0.5} />
    </button>
  );
};

export default SubmitButton;
