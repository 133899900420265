import { filter, size } from "lodash";
import * as React from "react";
import { useEffect } from "react";
import { useDatabase } from "..";
import { ProductCatalogueItemInterface } from "../../utilities/interfaces";

interface SelectedProductsContextInterface {
  addProduct: Function;
  removeProduct: Function;
  products: Array<ProductCatalogueItemInterface>;
}

const SelectedProductsContext = React.createContext<SelectedProductsContextInterface>(
  {} as SelectedProductsContextInterface
);

export const useSelectedProducts = () =>
  React.useContext(SelectedProductsContext);
interface ISelectedProductsProviderProps {}

const SelectedProductsProvider: React.FunctionComponent<ISelectedProductsProviderProps> = ({
  children,
}) => {
  const [products, setProducts] = React.useState<
    Array<ProductCatalogueItemInterface>
  >([]);
  const { updateUserData, userData } = useDatabase();

  const removeProduct = async (
    product_to_remove: ProductCatalogueItemInterface
  ) => {
    const filtered_products = filter(
      { ...products },
      (product: ProductCatalogueItemInterface) =>
        product.product_code !== product_to_remove.product_code
    );
    await updateUserData({
      products: size(filtered_products) !== 0 ? filtered_products : [],
    });
  };

  const addProduct = async (product_to_add: ProductCatalogueItemInterface) => {
    // remove product if category exists e.g manican can not have 2 jackets/bottoms at the same time
    let filtered_products = filter(
      { ...products },
      (product_item: ProductCatalogueItemInterface) =>
        product_item.product_category !== product_to_add.product_category
    );

    await updateUserData({ products: [...filtered_products, product_to_add] });
  };

  useEffect(() => {
    if (userData !== null) {
      setProducts(userData.products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const value = {
    products: products,
    addProduct: addProduct,
    removeProduct: removeProduct,
  };

  return (
    <SelectedProductsContext.Provider value={value}>
      {children}
    </SelectedProductsContext.Provider>
  );
};

export default SelectedProductsProvider;
