import * as React from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  ChangeBackground,
  EstimatedOutfitCost,
  LoaderComponent,
  MainHeader,
  ManicanCanvas,
} from "../../components";
import ProductCatalogue from "../../components/product-catalogue";
import { SelectedProductsProvider, useAuth, useDatabase } from "../../contexts";
import "./styles.scss";
import { EditDetails, ProfilePage } from "..";
import { AnimatePresence } from "framer-motion";

interface IWardrobeProps {}

const Wardrobe: React.FunctionComponent<IWardrobeProps> = (props) => {
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();
  const history = useHistory();

  const { currentUser } = useAuth();
  const { userData, getUserData, gettingUserData } = useDatabase();

  const getUserDataAfterLogin = async () => {
    try {
      await getUserData();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      history.push("/create-avatar");
    }
  };

  React.useEffect(() => {
    if (currentUser !== null) getUserDataAfterLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (loading && gettingUserData) {
    return <LoaderComponent />;
  }

  return (
    <SelectedProductsProvider>
      <div className="wardrobe-container">
        <div className="app-body">
          {location.pathname !== "/wardrobe/profile/edit-details" && (
            <MainHeader />
          )}
          <AnimatePresence exitBeforeEnter initial={false}>
            <Switch location={location} key={location.pathname}>
              <Route path="/wardrobe" exact={true}>
                <div>
                  <ManicanCanvas userData={userData} />
                  <ChangeBackground />
                  <ProductCatalogue />
                  <EstimatedOutfitCost />
                </div>
              </Route>
              <Route
                exact={true}
                path="/wardrobe/profile"
                component={ProfilePage}
              />
              <Route
                exact={true}
                path="/wardrobe/profile/edit-details"
                component={EditDetails}
              />
            </Switch>
          </AnimatePresence>
        </div>
      </div>
    </SelectedProductsProvider>
  );
};

export default Wardrobe;
