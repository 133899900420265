import { reduce } from "lodash";
import * as React from "react";
import { useSelectedProducts } from "../../contexts";
import "./styles.scss";

interface IEstimatedOutfitCostProps {}

const EstimatedOutfitCost: React.FunctionComponent<IEstimatedOutfitCostProps> = () => {
  const [estimatedCost, setEstimatedCost] = React.useState<number>(0);

  const { products } = useSelectedProducts();

  React.useEffect(() => {
    const cost = reduce(products, (acc, obj) => acc + obj.product_price, 0);
    setEstimatedCost(cost);
  }, [products]);

  return (
    <div className="estimated-outfit-cost">
      <div className="label">
        <strong>Estimated</strong> Outfit cost
      </div>
      <div className="cost">R {estimatedCost}</div>
    </div>
  );
};

export default EstimatedOutfitCost;
