import * as Yup from "yup";

export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 characters minimum"),
});

export const signUpSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name is too short - should be 2 characters minimum"),
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 characters minimum"),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const changeDetailsSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name is too short - should be 2 characters minimum"),
  currentPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 characters minimum"),
  currentPasswordConfirmed: Yup.bool()
    .required()
    .oneOf([true], "Please enter a correct password"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 characters minimum"),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
