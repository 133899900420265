interface BackgroundInterface {
  location: string;
  img: string;
}

export const backgrounds: Array<BackgroundInterface> = [
  {
    location: "Anchor Grey",
    img: require("./../../assets/manican-backgrounds/anchor-grey.jpg").default,
  },
  {
    location: "Anthracite",
    img: require("./../../assets/manican-backgrounds/anthracite.jpg").default,
  },
  {
    location: "Burlywood Brown",
    img: require("./../../assets/manican-backgrounds/burlywood-brown.jpg")
      .default,
  },
  {
    location: "Denim Blue",
    img: require("./../../assets/manican-backgrounds/denim-blue.jpg").default,
  },
  {
    location: "Logoon Breeze",
    img: require("./../../assets/manican-backgrounds/logoon-breeze.jpg")
      .default,
  },
  {
    location: "Marble Grey",
    img: require("./../../assets/manican-backgrounds/marble-grey.jpg").default,
  },
  {
    location: "Platinum Grey",
    img: require("./../../assets/manican-backgrounds/platinum-grey.jpg")
      .default,
  },
  {
    location: "Regency",
    img: require("./../../assets/manican-backgrounds/regency.jpg").default,
  },
];
