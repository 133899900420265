export const heads_with_ears: Array<string> = [
  require("./Head_1_Ear_3_C5.svg").default,
  require("./Head_1_Ear_3_C4.svg").default,
  require("./Head_1_Ear_3_C3.svg").default,
  require("./Head_1_Ear_3_C2.svg").default,
  require("./Head_1_Ear_3_C1.svg").default,

  require("./Head_1_Ear_2_C5.svg").default,
  require("./Head_1_Ear_2_C4.svg").default,
  require("./Head_1_Ear_2_C3.svg").default,
  require("./Head_1_Ear_2_C2.svg").default,
  require("./Head_1_Ear_2_C1.svg").default,

  require("./Head_1_Ear_1_C5.svg").default,
  require("./Head_1_Ear_1_C4.svg").default,
  require("./Head_1_Ear_1_C3.svg").default,
  require("./Head_1_Ear_1_C2.svg").default,
  require("./Head_1_Ear_1_C1.svg").default,

  require("./Head_2_Ear_3_C5.svg").default,
  require("./Head_2_Ear_3_C4.svg").default,
  require("./Head_2_Ear_3_C3.svg").default,
  require("./Head_2_Ear_3_C2.svg").default,
  require("./Head_2_Ear_3_C1.svg").default,

  require("./Head_2_Ear_2_C5.svg").default,
  require("./Head_2_Ear_2_C4.svg").default,
  require("./Head_2_Ear_2_C3.svg").default,
  require("./Head_2_Ear_2_C2.svg").default,
  require("./Head_2_Ear_2_C1.svg").default,

  require("./Head_2_Ear_1_C5.svg").default,
  require("./Head_2_Ear_1_C4.svg").default,
  require("./Head_2_Ear_1_C3.svg").default,
  require("./Head_2_Ear_1_C2.svg").default,
  require("./Head_2_Ear_1_C1.svg").default,

  require("./Head_3_Ear_3_C5.svg").default,
  require("./Head_3_Ear_3_C4.svg").default,
  require("./Head_3_Ear_3_C3.svg").default,
  require("./Head_3_Ear_3_C2.svg").default,
  require("./Head_3_Ear_3_C1.svg").default,

  require("./Head_3_Ear_2_C5.svg").default,
  require("./Head_3_Ear_2_C4.svg").default,
  require("./Head_3_Ear_2_C3.svg").default,
  require("./Head_3_Ear_2_C2.svg").default,
  require("./Head_3_Ear_2_C1.svg").default,

  require("./Head_3_Ear_1_C5.svg").default,
  require("./Head_3_Ear_1_C4.svg").default,
  require("./Head_3_Ear_1_C3.svg").default,
  require("./Head_3_Ear_1_C2.svg").default,
  require("./Head_3_Ear_1_C1.svg").default,

  require("./Head_4_Ear_3_C5.svg").default,
  require("./Head_4_Ear_3_C4.svg").default,
  require("./Head_4_Ear_3_C3.svg").default,
  require("./Head_4_Ear_3_C2.svg").default,
  require("./Head_4_Ear_3_C1.svg").default,

  require("./Head_4_Ear_2_C5.svg").default,
  require("./Head_4_Ear_2_C4.svg").default,
  require("./Head_4_Ear_2_C3.svg").default,
  require("./Head_4_Ear_2_C2.svg").default,
  require("./Head_4_Ear_2_C1.svg").default,

  require("./Head_4_Ear_1_C5.svg").default,
  require("./Head_4_Ear_1_C4.svg").default,
  require("./Head_4_Ear_1_C3.svg").default,
  require("./Head_4_Ear_1_C2.svg").default,
  require("./Head_4_Ear_1_C1.svg").default,

  require("./Head_5_Ear_2_C5.svg").default,
  require("./Head_5_Ear_2_C4.svg").default,
  require("./Head_5_Ear_2_C3.svg").default,
  require("./Head_5_Ear_2_C2.svg").default,
  require("./Head_5_Ear_2_C1.svg").default,

  require("./Head_5_Ear_1_C5.svg").default,
  require("./Head_5_Ear_1_C4.svg").default,
  require("./Head_5_Ear_1_C3.svg").default,
  require("./Head_5_Ear_1_C2.svg").default,
  require("./Head_5_Ear_1_C1.svg").default,

  require("./Head_6_Ear_3_C5.svg").default,
  require("./Head_6_Ear_3_C4.svg").default,
  require("./Head_6_Ear_3_C3.svg").default,
  require("./Head_6_Ear_3_C2.svg").default,
  require("./Head_6_Ear_3_C1.svg").default,

  require("./Head_6_Ear_2_C5.svg").default,
  require("./Head_6_Ear_2_C4.svg").default,
  require("./Head_6_Ear_2_C3.svg").default,
  require("./Head_6_Ear_2_C2.svg").default,
  require("./Head_6_Ear_2_C1.svg").default,

  require("./Head_6_Ear_1_C5.svg").default,
  require("./Head_6_Ear_1_C4.svg").default,
  require("./Head_6_Ear_1_C3.svg").default,
  require("./Head_6_Ear_1_C2.svg").default,
  require("./Head_6_Ear_1_C1.svg").default,
];
