import { map, omit } from "lodash";
import auth from "../screens/auth";
import {
  changeDetailsSchema,
  signInSchema,
  signUpSchema,
} from "./validation-schema";

const removeKeyFromArrayOfObjects = (items: any, key: string) => {
  return map(items, (item) => omit(item, key));
};

const setErrorMessage = (err: { code: string; message: string }) => {
  let message: string;

  if (err.code === "auth/email-already-in-use") {
    message =
      "The email address is already in use by another account, click the sign in button below to sign in/login or use a different email address.";
  } else if (err.code === "auth/user-not-found") {
    message =
      "We could not find an account with your email address, please check your email address and try again.";
  } else if (err.code === "auth/wrong-password") {
    message =
      "You have entered an incorrect password, please check your password and try again.";
  } else if (err.code === "auth/too-many-requests") {
    message = err.message;
  } else {
    message = "An Anknown error occured please refresh the page and try agin.";
  }
  return message;
};

export {
  auth,
  signInSchema,
  signUpSchema,
  changeDetailsSchema,
  setErrorMessage,
  removeKeyFromArrayOfObjects,
};
