import { isEmpty, isNull, isUndefined, omitBy } from "lodash";
import * as React from "react";
import { useAuth } from "..";
import { database } from "../../utilities/firebase";
import {
  ProductCatalogueItemInterface,
  UserDataInterface,
} from "../../utilities/interfaces";

interface DatabaseContextInterface {
  getProductList: Function;
  productList: Array<ProductCatalogueItemInterface>;
  setProductlist: Function;
  productListLoading: boolean;
  gettingUserData: boolean;
  getUserData: Function;
  updateUserData: Function;
  userData: UserDataInterface | null;
}

const DatabaseContext = React.createContext<DatabaseContextInterface>(
  {} as DatabaseContextInterface
);

export const useDatabase = () => React.useContext(DatabaseContext);
interface IDatabaseProviderProps {}

export const DatabaseProvider: React.FunctionComponent<IDatabaseProviderProps> = ({
  children,
}) => {
  const [productList, setProductlist] = React.useState<
    Array<ProductCatalogueItemInterface>
  >([]);
  const [userData, setUserData] = React.useState<UserDataInterface | null>(
    null
  );
  const [productListLoading, setPoductListLoading] = React.useState<boolean>(
    false
  );
  const [gettingUserData, setGettingUserData] = React.useState<boolean>(true);

  const { currentUser } = useAuth();

  const getUserData = () => {
    return new Promise<UserDataInterface>((resolve, reject) => {
      database
        .collection("users")
        .doc(currentUser?.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data() as UserDataInterface;
            setUserData(data);
            resolve(data);
          } else {
            reject("user data not found");
          }
          setGettingUserData(false);
        });
    });
  };

  const getProductList = () => {
    setPoductListLoading(true);
    database
      .collection("products-list")
      .get()
      .then((snapshot) => {
        setPoductListLoading(false);
        const data = snapshot.docs.map((doc) =>
          doc.data()
        ) as Array<ProductCatalogueItemInterface>;
        setProductlist(data);
      })
      .catch((err) => {
        setPoductListLoading(false);
        console.log(err);
      });
  };

  const updateUserData = (data: UserDataInterface) => {
    const document = database.collection("users").doc(currentUser?.uid);
    // add new data if it exists else update
    return document.get().then((doc) => {
      if (doc.exists) {
        return document
          .update({
            ...omitBy(data, isUndefined),
          })
          .then(async () => {
            try {
              const user_data = await getUserData();
              setUserData(user_data);
            } catch (err) {
              console.log(err);
            }
          });
      } else {
        return document
          .set({
            waistSize: "32",
            shirtSize: "S",
            ...omitBy(data, isEmpty || isUndefined || isNull),
          })
          .then(async () => {
            try {
              const user_data = await getUserData();

              setUserData(user_data);
            } catch (err) {
              console.log(err);
            }
          });
      }
    });
  };

  const value: DatabaseContextInterface = {
    getUserData: getUserData,
    userData: userData,
    updateUserData: updateUserData,
    getProductList: getProductList,
    productList: productList,
    setProductlist: setProductlist,
    productListLoading: productListLoading,
    gettingUserData: gettingUserData,
  };

  React.useEffect(() => {
    getProductList();
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
};
