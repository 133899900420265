import * as React from "react";
import { useAuth } from "../../contexts";
import {
  facebookAuthProvider,
  googleAuthProvider,
} from "../../utilities/firebase";

interface ISignInWithProviderComponentProps {}

const SignInWithProviderComponent: React.FunctionComponent<ISignInWithProviderComponentProps> = (
  props
) => {
  const { signInWithProvider } = useAuth();

  const signInUserWithProvider = async (provider: any) => {
    try {
      await signInWithProvider(provider);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sign-up-using">
      <div className="sign-up-using-text">or signup using:</div>
      <div className="links">
        <button
          onClick={(e) => {
            e.preventDefault();
            signInUserWithProvider(facebookAuthProvider);
          }}
        >
          facebook
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            signInUserWithProvider(googleAuthProvider);
          }}
        >
          google
        </button>
      </div>
    </div>
  );
};

export default SignInWithProviderComponent;
