export const hair: Array<string> = [
  require("./Hair_1_M_C1.svg").default,
  require("./Hair_2_M_C1.svg").default,
  require("./Hair_3_M_C1.svg").default,
  require("./Hair_4_M_C1.svg").default,
  require("./Hair_5_M_C1.svg").default,
  require("./Hair_6_M_C1.svg").default,
  require("./Hair_7_M_C1.svg").default,
  require("./Hair_8_M_C1.svg").default,
  require("./Hair_9_M_C1.svg").default,
  require("./Hair_10_M_C1.svg").default,
  require("./Hair_11_M_C1.svg").default,
  require("./Hair_12_M_C1.svg").default,

  require("./Hair_15_F_C1.svg").default,
  require("./Hair_16_F_C1.svg").default,
  require("./Hair_17_F_C1.svg").default,
  require("./Hair_18_F_C1.svg").default,
  require("./Hair_19_F_C1.svg").default,
  require("./Hair_20_F_C1.svg").default,

  require("./Hair_1_M_C2.svg").default,
  require("./Hair_2_M_C2.svg").default,
  require("./Hair_3_M_C2.svg").default,
  require("./Hair_4_M_C2.svg").default,
  require("./Hair_5_M_C2.svg").default,
  require("./Hair_6_M_C2.svg").default,
  require("./Hair_7_M_C2.svg").default,
  require("./Hair_8_M_C2.svg").default,
  require("./Hair_9_M_C2.svg").default,
  require("./Hair_10_M_C2.svg").default,
  require("./Hair_11_M_C2.svg").default,
  require("./Hair_12_M_C2.svg").default,

  require("./Hair_15_F_C2.svg").default,
  require("./Hair_16_F_C2.svg").default,
  require("./Hair_17_F_C2.svg").default,
  require("./Hair_18_F_C2.svg").default,
  require("./Hair_19_F_C2.svg").default,
  require("./Hair_20_F_C2.svg").default,

  require("./Hair_1_M_C3.svg").default,
  require("./Hair_2_M_C3.svg").default,
  require("./Hair_3_M_C3.svg").default,
  require("./Hair_4_M_C3.svg").default,
  require("./Hair_5_M_C3.svg").default,
  require("./Hair_6_M_C3.svg").default,
  require("./Hair_7_M_C3.svg").default,
  require("./Hair_8_M_C3.svg").default,
  require("./Hair_9_M_C3.svg").default,
  require("./Hair_10_M_C3.svg").default,
  require("./Hair_11_M_C3.svg").default,
  require("./Hair_12_M_C3.svg").default,

  require("./Hair_17_F_C3.svg").default,
  require("./Hair_18_F_C3.svg").default,
  require("./Hair_19_F_C3.svg").default,
  require("./Hair_15_F_C3.svg").default,
  require("./Hair_16_F_C3.svg").default,
  require("./Hair_20_F_C3.svg").default,

  require("./Hair_1_M_C4.svg").default,
  require("./Hair_2_M_C4.svg").default,
  require("./Hair_3_M_C4.svg").default,
  require("./Hair_4_M_C4.svg").default,
  require("./Hair_5_M_C4.svg").default,
  require("./Hair_6_M_C4.svg").default,
  require("./Hair_7_M_C4.svg").default,
  require("./Hair_8_M_C4.svg").default,
  require("./Hair_9_M_C4.svg").default,
  require("./Hair_10_M_C4.svg").default,
  require("./Hair_11_M_C4.svg").default,
  require("./Hair_12_M_C4.svg").default,

  require("./Hair_15_F_C4.svg").default,
  require("./Hair_16_F_C4.svg").default,
  require("./Hair_17_F_C4.svg").default,
  require("./Hair_18_F_C4.svg").default,
  require("./Hair_19_F_C4.svg").default,
  require("./Hair_20_F_C4.svg").default,

  require("./Hair_13.svg").default,
  require("./Hair_14.svg").default,
  require("./Hair_15_F_C5.svg").default,
  require("./Hair_16_F_C5.svg").default,
  require("./Hair_17_F_C5.svg").default,
  require("./Hair_18_F_C5.svg").default,
  require("./Hair_19_F_C5.svg").default,
  require("./Hair_20_F_C5.svg").default,

  require("./Hair_1_M_C5.svg").default,
  require("./Hair_2_M_C5.svg").default,
  require("./Hair_3_M_C5.svg").default,
  require("./Hair_4_M_C5.svg").default,
  require("./Hair_5_M_C5.svg").default,
  require("./Hair_6_M_C5.svg").default,
  require("./Hair_7_M_C5.svg").default,
  require("./Hair_8_M_C5.svg").default,
  require("./Hair_9_M_C5.svg").default,
  require("./Hair_10_M_C5.svg").default,
  require("./Hair_11_M_C5.svg").default,
  require("./Hair_12_M_C5.svg").default,
];
