export const lips: Array<string> = [
  require("./Lip_10_F.svg").default,
  require("./Lip_11_F.svg").default,
  require("./Lip_12_F.svg").default,
  require("./Lip_1_F.svg").default,
  require("./Lip_1_M.svg").default,
  require("./Lip_2_F.svg").default,
  require("./Lip_2_M.svg").default,
  require("./Lip_3_F.svg").default,
  require("./Lip_3_M.svg").default,
  require("./Lip_4_F.svg").default,
  require("./Lip_4_M.svg").default,
  require("./Lip_5_F.svg").default,
  require("./Lip_5_M.svg").default,
  require("./Lip_6_F.svg").default,
  require("./Lip_6_M.svg").default,
  require("./Lip_7_F.svg").default,
  require("./Lip_7_M.svg").default,
  require("./Lip_8_F.svg").default,
  require("./Lip_8_M.svg").default,
  require("./Lip_9_F.svg").default,
  require("./Lip_9_M.svg").default,
];
