import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { map, sortBy } from "lodash";
import * as React from "react";
import { useDatabase } from "../../contexts";
import { backgrounds } from "../../utilities/data";
import "./styles.scss";

interface IChangeBackgroundProps {}

const ChangeBackground: React.FunctionComponent<IChangeBackgroundProps> = () => {
  const [changeBackground, setChangeBackground] = React.useState<boolean>(
    false
  );

  const { updateUserData } = useDatabase();

  const saveBackgroundToProfileAndUpdate = async (background: string) => {
    setChangeBackground(!changeBackground);
    await updateUserData({ manicanBackground: background });
  };

  return (
    <div className="change-background">
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.98 }}
        className={`change-background-link ${changeBackground ? "active" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          setChangeBackground(!changeBackground);
        }}
      >
        Change Background
      </motion.button>
      {changeBackground && (
        <motion.div layout className="background-list">
          {map(sortBy(backgrounds, ["location"]), (item) => (
            <motion.button
              style={{
                background: `url('${item.img}') no-repeat center/cover`,
              }}
              className="background-item"
              key={item.location}
              onClick={(e) => {
                e.preventDefault();
                saveBackgroundToProfileAndUpdate(item.img);
              }}
            >
              <div className="background-name">{item.location}</div>
              <div className="plus-icon">
                <FontAwesomeIcon icon={faPlusCircle} />
              </div>
            </motion.button>
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default ChangeBackground;
