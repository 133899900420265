import { accessories } from "./accessories";
import { beards } from "./beards";
import { eyebrows } from "./eyebrows";
import { eyes } from "./eyes";
import { hair } from "./hair";
import { heads_with_ears } from "./head-with-ears";
import { lips } from "./lips";
import { noses } from "./noses";

interface AvatarAccesoriesInteface {
  accessories: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
  beards: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
  eyebrows: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
  eyes: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
  hair: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
  heads_with_ears: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
  lips: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
  noses: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
}

export const avatar_templates: AvatarAccesoriesInteface = {
  heads_with_ears: {
    label: "Head",
    items: heads_with_ears,
    z_index: 0,
    top_position: 0,
  },
  eyes: {
    label: "Eyes",
    items: eyes,
    z_index: 1,
    top_position: 110,
  },
  noses: {
    label: "Nose",
    items: noses,
    z_index: 2,
    top_position: 100,
  },
  eyebrows: {
    label: "Eyebrows",
    items: eyebrows,
    z_index: 3,
    top_position: 100,
  },

  lips: {
    label: "Lips",
    items: lips,
    z_index: 3,
    top_position: 170,
  },
  hair: {
    label: "Hair",
    items: hair,
    z_index: 4,
    top_position: 0,
  },
  beards: {
    label: "Beard",
    items: beards,
    z_index: 4,
    top_position: 100,
  },
  accessories: {
    label: "Accessories",
    items: accessories,
    z_index: 5,
    top_position: 105,
  },
};
