import * as React from "react";
import "./styles.scss";

interface IFormFeedBackProps {
  errorMessage: string | null;
  successMessage: string | null;
}

const FormFeedBack: React.FunctionComponent<IFormFeedBackProps> = ({
  errorMessage,
  successMessage,
}) => {
  return (
    <div
      className={`form-feedback 
            ${errorMessage ? "error" : " "}
            ${successMessage ? "success" : " "}`}
    >
      {errorMessage}
      {successMessage}
    </div>
  );
};

export default FormFeedBack;
