import { AnimatePresence } from "framer-motion";
import * as React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouterProps,
  Switch,
} from "react-router-dom";
import { useAuth } from "../contexts";
import { Auth, NotFound, SharedAvatarScreen, Wardrobe } from "../screens";
import CreateAvatarScreen from "../screens/create-avatar";

const MainRouter: React.FunctionComponent = () => {
  const { currentUser } = useAuth();

  return (
    <Router>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              currentUser ? (
                <Redirect to="/wardrobe" />
              ) : (
                <Redirect to="/auth" />
              )
            }
          />
          <Route
            path="/auth"
            render={(props: RouterProps) =>
              currentUser ? <Redirect to="/" /> : <Auth {...props} />
            }
          />
          <Route
            path="/create-avatar"
            render={(props: RouterProps) =>
              currentUser ? (
                <CreateAvatarScreen {...props} />
              ) : (
                <Redirect to="/auth" />
              )
            }
          ></Route>
          <Route
            path="/wardrobe"
            render={(props: RouterProps) =>
              currentUser ? <Wardrobe {...props} /> : <Redirect to="/auth" />
            }
          />
          <Route path="/shared-avatar" component={SharedAvatarScreen} />
          <Route component={NotFound} />
        </Switch>
      </AnimatePresence>
    </Router>
  );
};

export default MainRouter;
