import * as React from "react";
import Loader from "react-loader";
import "./style.scss";

interface ILoaderComponentProps {
  loading_text?: string;
}

const LoaderComponent: React.FunctionComponent<ILoaderComponentProps> = ({
  loading_text,
}) => {
  return (
    <div className="loader-container">
      <div>
        <div className="header">
          <div className="heading">
            OUR. <br />
            MKM <div>STYLE</div>
          </div>
        </div>
        <div className="loader-icon">
          <Loader loaded={false} color="#000" scale={1.3} />
          <div>{loading_text ? loading_text : "Loading"}...</div>
        </div>
        <div className="loading-text">
          create, share <div>&amp; win</div>
        </div>
      </div>
    </div>
  );
};

export default LoaderComponent;
