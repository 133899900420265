import * as React from "react";
import { LoaderComponent, MainLogo, SubmitButton } from "../../components";
import { avatar_templates } from "./avatar-creator-assets";
import { map, replace, toLower } from "lodash";
import AvatarTemplateList from "./template-list";
import FabricCanvas from "./fabric-canvas";
import { ActiveCanvasElementInterface } from "../../utilities/interfaces";
import "./styles.scss";
import { FormFeedBack, useDatabase } from "../../contexts";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
interface ICreateAvatarScreenProps {}

const CreateAvatarScreen: React.FunctionComponent<ICreateAvatarScreenProps> = (
  props
) => {
  const [activeTemplateTab, setActiveTemplateTab] = React.useState<string>(
    "head"
  );
  const [
    activeCanvasElement,
    setActiveCanvasElement,
  ] = React.useState<ActiveCanvasElementInterface | null>(null);
  const [saveImageToProfile, setSaveImageToProfile] = React.useState(false);
  const [essentialAssetsLoaded, setEssentialAssetsLoaded] = React.useState(
    false
  );
  const [error, setError] = React.useState<string | null>(null);

  const addToCanvas = (data: ActiveCanvasElementInterface) => {
    setActiveCanvasElement(data);
  };

  const { gettingUserData: loading } = useDatabase();

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="create-avatar">
      <div className="app-body">
        <Link to="/wardrobe">
          <div className="header">
            <MainLogo />
            create your avatar
          </div>
        </Link>
        <div className="avatar-creator">
          <FabricCanvas
            setEssentialAssetsLoaded={setEssentialAssetsLoaded}
            activeCanvasElement={activeCanvasElement}
            saveImageToProfile={saveImageToProfile}
            setSaveImageToProfile={setSaveImageToProfile}
            setError={setError}
          />
          <div className="tabs">
            <div className="tabs-heading">
              {map(avatar_templates, (item) => (
                <button
                  style={{
                    background: `#e8e8e8 url('${item.items[2]}') no-repeat center/contain`,
                  }}
                  className={`tab-item ${toLower(
                    replace(item.label, " ", "_")
                  )} ${
                    activeTemplateTab === toLower(item.label) ? "active" : ""
                  }`}
                  key={item.label}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTemplateTab(toLower(item.label));
                  }}
                >
                  <label>{item.label}</label>
                </button>
              ))}
            </div>
            <div className="tabs-content">
              {map(avatar_templates, (item) => {
                if (toLower(item.label) === activeTemplateTab) {
                  return (
                    <AvatarTemplateList
                      templates={item}
                      addToCanvas={addToCanvas}
                      key={item.label}
                    />
                  );
                }
              })}
            </div>
          </div>

          <motion.div
            whileHover={{ scale: !essentialAssetsLoaded ? 1 : 1.01 }}
            whileTap={{ scale: !essentialAssetsLoaded ? 1 : 0.98 }}
            className="links-container"
          >
            <SubmitButton
              title="next"
              loading={saveImageToProfile}
              cssClass="button-blue save-avatar"
              disabled={saveImageToProfile || !essentialAssetsLoaded}
              action={() => {
                setSaveImageToProfile(true);
              }}
            />
          </motion.div>

          <FormFeedBack errorMessage={error} successMessage="" />
        </div>
      </div>
    </div>
  );
};

export default CreateAvatarScreen;
