import React, {
  useContext,
  ReactChild,
  ReactChildren,
  useState,
  useEffect,
} from "react";
import firebase from "firebase";
import { auth } from "../../utilities/firebase";
import { SignInInterface, SignUpInterface } from "../../utilities/interfaces";

interface AuthContextInterface {
  currentUser: firebase.User | null;
  signUp: Function;
  signIn: Function;
  signOut: Function;
  resetPassword: Function;
  updateAvatar: Function;
  updatePasswordAndDisplayName: Function;
  reAthenticateUser: Function;
  signInWithProvider: Function;
}

const AuthContext = React.createContext<AuthContextInterface>(
  {} as AuthContextInterface
);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
}: {
  children: ReactChild | ReactChildren;
}) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const signUp = async (values: SignUpInterface) => {
    const { email, password, name } = values;
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        setCurrentUser(result.user);
        result.user?.updateProfile({ displayName: name });
      });
  };

  const signIn = (values: SignInInterface) => {
    const { email, password, keepMeLoggedIn } = values;
    const auth_persistance = keepMeLoggedIn
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    return auth
      .setPersistence(auth_persistance)
      .then(() => auth.signInWithEmailAndPassword(email, password));
  };

  const signInWithProvider = (provider: any) => {
    return auth.signInWithPopup(provider);
  };

  const signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    return auth.signOut();
  };

  const resetPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email);
  };

  const updatePasswordAndDisplayName = (values: {
    password: string;
    displayName: string;
  }) => {
    const { displayName, password } = values;
    return currentUser
      ?.updatePassword(password)
      .then(() => currentUser.updateProfile({ displayName: displayName }));
  };

  const reAthenticateUser = (password: string) => {
    if (currentUser !== null && currentUser.email !== null) {
      const { email } = currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      );
      return auth.currentUser?.reauthenticateWithCredential(credential);
    }
  };

  const updateAvatar = (url: string) => {
    return currentUser?.updateProfile({
      photoURL: url,
    });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // logout all other active sessions when passowrd is changed
    const unsubscribe = auth.onIdTokenChanged(() => {});

    return unsubscribe;
  }, []);

  const value: AuthContextInterface = {
    currentUser: currentUser,
    signUp: signUp,
    signIn: signIn,
    signOut: signOut,
    resetPassword: resetPassword,
    updateAvatar: updateAvatar,
    updatePasswordAndDisplayName: updatePasswordAndDisplayName,
    reAthenticateUser: reAthenticateUser,
    signInWithProvider: signInWithProvider,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
