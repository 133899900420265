export const eyes: Array<string> = [
  require("./Eye_1_M_C1.svg").default,
  require("./Eye_2_M_C1.svg").default,
  require("./Eye_3_M_C1.svg").default,
  require("./Eye_4_M_C1.svg").default,
  require("./Eye_5_F_C1.svg").default,
  require("./Eye_6_F_C1.svg").default,
  require("./Eye_7_F_C1.svg").default,

  require("./Eye_1_M_C2.svg").default,
  require("./Eye_2_M_C2.svg").default,
  require("./Eye_3_M_C2.svg").default,
  require("./Eye_4_M_C2.svg").default,
  require("./Eye_5_F_C2.svg").default,
  require("./Eye_6_F_C2.svg").default,
  require("./Eye_7_F_C2.svg").default,

  require("./Eye_1_M_C3.svg").default,
  require("./Eye_2_M_C3.svg").default,
  require("./Eye_3_M_C3.svg").default,
  require("./Eye_4_M_C3.svg").default,
  require("./Eye_5_F_C3.svg").default,
  require("./Eye_6_F_C3.svg").default,
  require("./Eye_7_F_C3.svg").default,

  require("./Eye_1_M_C4.svg").default,
  require("./Eye_2_M_C4.svg").default,
  require("./Eye_3_M_C4.svg").default,
  require("./Eye_4_M_C4.svg").default,
  require("./Eye_5_F_C4.svg").default,
  require("./Eye_6_F_C4.svg").default,
  require("./Eye_7_F_C4.svg").default,

  require("./Eye_1_M_C5.svg").default,
  require("./Eye_2_M_C5.svg").default,
  require("./Eye_3_M_C5.svg").default,
  require("./Eye_4_M_C5.svg").default,
  require("./Eye_5_F_C5.svg").default,
  require("./Eye_6_F_C5.svg").default,
  require("./Eye_7_F_C5.svg").default,

  require("./Eye_1_M_C6.svg").default,
  require("./Eye_2_M_C6.svg").default,
  require("./Eye_3_M_C6.svg").default,
  require("./Eye_4_M_C6.svg").default,
  require("./Eye_5_F_C6.svg").default,
  require("./Eye_6_F_C6.svg").default,
  require("./Eye_7_F_C6.svg").default,
];
