import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { SignUpScreen, SignInScreen } from "..";
import "./styles.scss";
interface AuthProps {}

const Auth: React.FunctionComponent<AuthProps> = (props) => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="/auth" exact component={SignInScreen} />
        <Route path="/auth/sign-up" exact component={SignUpScreen} />
      </Switch>
    </div>
  );
};

export default Auth;
