export const manican_bodies: {
  c1: string;
  c2: string;
  c3: string;
  c4: string;
  c5: string;
} = {
  c1: require("../../assets/manican-bodies/Body_C1.svg").default,
  c2: require("../../assets/manican-bodies/Body_C2.svg").default,
  c3: require("../../assets/manican-bodies/Body_C3.svg").default,
  c4: require("../../assets/manican-bodies/Body_C4.svg").default,
  c5: require("../../assets/manican-bodies/Body_C5.svg").default,
};
