import * as React from "react";
import Logo from "../../assets/icons/logo-piece-white.png";
import "./styles.scss";

interface IMainLogoProps {}

const MainLogo: React.FunctionComponent<IMainLogoProps> = (props) => {
  return (
    <div className="main-logo">
      <img src={Logo} alt="Markhams Wardrobe" />
    </div>
  );
};

export default MainLogo;
