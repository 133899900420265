import { replace, toLower } from "lodash";
import * as React from "react";

interface IAvataTemplateListItemProps {
  template: string;
  template_type: string;
  z_index: number;
  top_position: number;
  addToCanvas: Function;
}

const AvataTemplateListItem: React.FunctionComponent<IAvataTemplateListItemProps> = ({
  template,
  template_type,
  z_index,
  top_position,
  addToCanvas,
}) => {
  return (
    <button
      className={`avatar-template-list-item ${toLower(
        replace(template_type, " ", "_")
      )}`}
      style={{
        background: `#e8e8e8 url('${template}') no-repeat center/contain`,
      }}
      onClick={(e) => {
        e.preventDefault();
        addToCanvas({
          img_element: template,
          template_type: toLower(template_type),
          z_index: z_index,
          top_position: top_position,
        });
      }}
      key={template}
    >
      {/*  {template && <img src={template} alt="" />} */}
      {!template && "None"}
    </button>
  );
};

export default AvataTemplateListItem;
