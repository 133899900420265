export const eyebrows: Array<string> = [
  require("./Brow_1_C1.svg").default,
  require("./Brow_2_C1.svg").default,
  require("./Brow_3_C1.svg").default,
  require("./Brow_4_C1.svg").default,
  require("./Brow_5_C1.svg").default,
  require("./Brow_6_C1.svg").default,
  require("./Brow_7_C1.svg").default,

  require("./Brow_1_C2.svg").default,
  require("./Brow_2_C2.svg").default,
  require("./Brow_3_C2.svg").default,
  require("./Brow_4_C2.svg").default,
  require("./Brow_5_C2.svg").default,
  require("./Brow_6_C2.svg").default,
  require("./Brow_7_C2.svg").default,

  require("./Brow_1_C3.svg").default,
  require("./Brow_2_C3.svg").default,
  require("./Brow_3_C3.svg").default,
  require("./Brow_4_C3.svg").default,
  require("./Brow_5_C3.svg").default,
  require("./Brow_6_C3.svg").default,
  require("./Brow_7_C3.svg").default,

  require("./Brow_1_C4.svg").default,
  require("./Brow_2_C4.svg").default,
  require("./Brow_3_C4.svg").default,
  require("./Brow_4_C4.svg").default,
  require("./Brow_5_C4.svg").default,
  require("./Brow_6_C4.svg").default,
  require("./Brow_7_C4.svg").default,

  require("./Brow_1_C5.svg").default,
  require("./Brow_2_C5.svg").default,
  require("./Brow_3_C5.svg").default,
  require("./Brow_4_C5.svg").default,
  require("./Brow_5_C5.svg").default,
  require("./Brow_6_C5.svg").default,
  require("./Brow_7_C5.svg").default,
];
