import * as React from "react";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OurMkmTextHeader } from "../../components";
import { useStorage } from "../../contexts";
import "./style.scss";
interface ISharedAvatarScreenProps {}

const SharedAvatarScreen: React.FunctionComponent<ISharedAvatarScreenProps> = (
  props
) => {
  const [avatar, setAvatar] = useState<string | null>(null);

  const { getSharedImage } = useStorage();

  const getImageFromStorage = async (image_name: string | null) => {
    if (image_name === null) return;
    const avatar = await getSharedImage(image_name);
    setAvatar(avatar);
  };

  const getImageNameFormUrl = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const image_name = url.searchParams.get("avatar");
    getImageFromStorage(image_name);
  };

  useEffect(() => {
    getImageNameFormUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="shared-avatar-screen">
      <Helmet>
        <meta property="og:image" content={avatar ? avatar : ""} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Link to="/">
        <OurMkmTextHeader />
      </Link>

      <div className="shared-avatar-container">
        <img src={avatar ? avatar : ""} alt="" />
      </div>
    </div>
  );
};

export default SharedAvatarScreen;
