import moment from "moment";
import * as React from "react";
import { useAuth } from "..";
import { storage } from "../../utilities/firebase";

interface StorageContextInterface {
  uploadAvatar: Function;
  getSharedImage: Function;
  uploadSharedAvatar: Function;
  checkIfAvatarExists: Function;
}

const StorageContext = React.createContext<StorageContextInterface>(
  {} as StorageContextInterface
);

export const useStorage = () => React.useContext(StorageContext);
interface IDatabaseProviderProps {}

export const StorageProvider: React.FunctionComponent<IDatabaseProviderProps> = ({
  children,
}) => {
  const { currentUser } = useAuth();

  const getSharedImage = (image_name: string) => {
    return storage
      .ref()
      .child("shared")
      .child(image_name)
      .getDownloadURL()
      .then((url) => url);
  };

  const uploadAvatar = (image: string) => {
    const storageRef = storage.ref();
    const avatarsRef = storageRef.child(
      `/avatars/${currentUser?.uid}/avatar.png`
    );
    return avatarsRef.putString(image, "data_url").then((snapshot) => {
      return snapshot.ref.getDownloadURL().then((url) => {
        return url;
      });
    });
  };

  const uploadSharedAvatar = (image: string) => {
    const storageRef = storage.ref();
    const image_name = `${currentUser?.displayName}_avatar_${moment().format(
      "DD-MM-YYYY"
    )}.jpeg`;
    const avatarsRef = storageRef.child(`/shared/${image_name}`);
    return avatarsRef.putString(image, "data_url").then((snapshot) => {
      return snapshot.ref.getDownloadURL().then((url) => {
        return { url, image_name };
      });
    });
  };

  const checkIfAvatarExists = () => {
    const storageRef = storage.ref();
    const avatarsRef = storageRef.child(
      `/avatars/${currentUser?.uid}/avatar.png`
    );
    return avatarsRef.getDownloadURL().then((url) => url);
  };

  const value: StorageContextInterface = {
    getSharedImage: getSharedImage,
    uploadAvatar: uploadAvatar,
    uploadSharedAvatar: uploadSharedAvatar,
    checkIfAvatarExists: checkIfAvatarExists,
  };

  return (
    <StorageContext.Provider value={value}>{children}</StorageContext.Provider>
  );
};
