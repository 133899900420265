import * as React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import fabric from "fabric";
import "./style.scss";
import { useStorage } from "../../contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareSquare,
  faWindowClose,
} from "@fortawesome/free-regular-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

const ShareButton = ({
  children,
  buildSharablePicture,
  shareOption,
}: {
  children: React.ReactNode;
  buildSharablePicture: Function;
  shareOption: string;
}) => (
  <button
    className={`share-button ${shareOption}`}
    onClick={(e) => {
      e.preventDefault();
      buildSharablePicture(shareOption);
    }}
  >
    {children}
  </button>
);

interface IShareComponentProps {
  canvasElement: fabric.fabric.StaticCanvas | undefined;
  setLoading: Function;
  loading: boolean;
}
const ShareComponent: React.FunctionComponent<IShareComponentProps> = ({
  canvasElement,
  setLoading,
  loading,
}) => {
  const [canShare, setCanShare] = React.useState<any | undefined>(undefined);
  const [socialShareUrl, setSocialShareUrl] = React.useState<string>("");
  const [shareOption, setShareOption] = React.useState<string>("");
  const [
    showShareImagePreview,
    setShowShareImagePreview,
  ] = React.useState<boolean>(false);
  //const [shareFile, setShareFile] = React.useState<File>();
  const { uploadSharedAvatar } = useStorage();

  const buildSharablePicture = (share_option: string) => {
    buildSharableCanvasPicture(share_option);
    setLoading(true);
  };

  const check_if_sharing_is_available_ref = React.useRef<HTMLButtonElement>(
    null
  );

  const buildSharableCanvasPicture = (share_option: string) => {
    // clone current canvas
    const sharerable_canvas = new fabric.fabric.StaticCanvas(
      "sharerable-canvas",
      {
        renderOnAddRemove: true,
        height: 600,
        width: 800,
      }
    );

    const current_canvas_image = canvasElement?.toDataURL({
      format: "png",
      quality: 1,
      multiplier: 4,
    });

    if (!current_canvas_image) return;
    fabric.fabric.Image.fromURL(current_canvas_image, (img) => {
      const { width: img_width, height: img_height } = img;

      if (img_width !== undefined && img_height !== undefined) {
        sharerable_canvas.setBackgroundImage(
          img,
          sharerable_canvas.renderAll.bind(canvasElement),
          {
            scaleX: sharerable_canvas.getWidth() / img_width,
            scaleY: sharerable_canvas.getHeight() / img_height,
          }
        );

        fabric.fabric.Image.fromURL(
          require("../../assets/images/avatar-export-our-mkm-text.png").default,
          (our_mkm_text) => {
            fabric.fabric.Image.fromURL(
              require("../../assets/images/avatar-export-logo.png").default,
              async (logo) => {
                const {
                  width: our_mkm_text_width,
                  height: our_mkm_text_height,
                } = our_mkm_text;
                const { width: logo_width, height: logo_height } = logo;

                if (
                  our_mkm_text_width !== undefined &&
                  logo_width !== undefined &&
                  our_mkm_text_height !== undefined &&
                  logo_height !== undefined
                ) {
                  sharerable_canvas
                    .add(
                      our_mkm_text.set({
                        left: 30,
                      })
                    )
                    .centerObjectV(our_mkm_text);

                  sharerable_canvas
                    .add(
                      logo.set({
                        left: sharerable_canvas.getWidth() - logo_width + 2,
                      })
                    )
                    .centerObjectV(logo);

                  const sharerable_canvas_image = sharerable_canvas.toDataURL({
                    format: "jpeg",
                    quality: 1,
                    enableRetinaScaling: true,
                  });

                  const image = await uploadSharedAvatar(
                    sharerable_canvas_image
                  );

                  setLoading(false);
                  setShowShareImagePreview(true);
                  setShareOption(share_option);
                  setSocialShareUrl(image.url);

                  /*  const file = new File([image.url], image.image_name, {
                    type: "image/jpeg",
                  }); */
                  //setShareFile(file);
                }
              }
            );
          }
        );
      }
    });
  };

  const shareToNavigator = async () => {
    try {
      await (navigator.share as any)({
        title: "OUR.MKM Style",
        text: "Check out my avatar created on OUR.MKM Style",
        url: socialShareUrl,
      });
    } catch (err) {
      console.log("an error occured while sharing", err);
    }
  };

  React.useEffect(() => {
    setCanShare(true);
    check_if_sharing_is_available_ref?.current?.click();
  }, []);

  if (loading) return <></>;
  return (
    <div className="share-component">
      <AnimatePresence>
        {showShareImagePreview && (
          <motion.div className="share-image-container">
            <button
              className="icon-close"
              onClick={(e) => {
                e.preventDefault();
                setShowShareImagePreview(false);
              }}
            >
              <FontAwesomeIcon icon={faWindowClose} color="#fff" />
            </button>
            <div className="share-button-item">
              {shareOption === "facebook" && (
                <FacebookShareButton
                  url={socialShareUrl}
                  beforeOnClick={() => {
                    setShowShareImagePreview(false);
                  }}
                >
                  <FacebookIcon size={32} round />
                  <span className={shareOption}>
                    share avatar via {shareOption}
                  </span>
                </FacebookShareButton>
              )}

              {shareOption === "whatsapp" && (
                <WhatsappShareButton
                  url={socialShareUrl}
                  beforeOnClick={() => {
                    setShowShareImagePreview(false);
                  }}
                >
                  <WhatsappIcon size={32} round />
                  <span className={shareOption}>
                    share avatar via {shareOption}
                  </span>
                </WhatsappShareButton>
              )}

              {shareOption === "twitter" && (
                <TwitterShareButton
                  url={socialShareUrl}
                  beforeOnClick={() => {
                    setShowShareImagePreview(false);
                  }}
                >
                  <TwitterIcon size={32} round />
                  <span className={shareOption}>
                    share avatar via {shareOption}
                  </span>
                </TwitterShareButton>
              )}

              {shareOption === "navigator-share" && (
                <button
                  id="navigator-share"
                  onClick={(e) => {
                    e.preventDefault();
                    shareToNavigator();
                  }}
                >
                  <FontAwesomeIcon icon={faShareSquare} color="#fff" />
                  <span className={shareOption}>share avatar via browser</span>
                </button>
              )}
            </div>

            <div className="image-container">
              <img
                src={socialShareUrl}
                alt="OUR.MKM Style Share"
                className="share-image"
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="share-buttons">
        <ShareButton
          buildSharablePicture={buildSharablePicture}
          shareOption="facebook"
        >
          <FacebookIcon size={32} round />
        </ShareButton>

        <ShareButton
          buildSharablePicture={buildSharablePicture}
          shareOption="whatsapp"
        >
          <WhatsappIcon size={32} round />
        </ShareButton>

        <ShareButton
          buildSharablePicture={buildSharablePicture}
          shareOption="twitter"
        >
          <TwitterIcon size={32} round />
        </ShareButton>

        {canShare && (
          <ShareButton
            buildSharablePicture={buildSharablePicture}
            shareOption="navigator-share"
          >
            <FontAwesomeIcon icon={faShareSquare} color="#fff" />
          </ShareButton>
        )}
      </div>
    </div>
  );
};

export default ShareComponent;
