import * as React from "react";
import "./style.scss";

interface IOurMkmTextHeaderProps {}

const OurMkmTextHeader: React.FunctionComponent<IOurMkmTextHeaderProps> = (
  props
) => {
  return (
    <div className="markhams-text">
      our.
      <br />
      mkm
      <div>style</div>
    </div>
  );
};

export default OurMkmTextHeader;
