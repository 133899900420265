import { filter, includes, map, sample, size, toLower } from "lodash";
import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import { useEffect } from "react";
import { useDatabase } from "../../contexts";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";

const waist_sizes: Array<string> = ["28", "30", "32", "34", "36", "38", "40"];
const shirt_sizes: Array<string> = [
  "XXS",
  "XS",
  "S",
  "M",
  "ML",
  "L",
  "XL",
  "2XL",
];

interface IEditSizesProps {
  setEditSizes: Function;
  profileWaistSize: string | undefined;
  profileShirtSize: string | undefined;
}

const EditSizes: React.FunctionComponent<IEditSizesProps> = ({
  setEditSizes,
  profileWaistSize,
  profileShirtSize,
}) => {
  const [shirtThumbnail, setShirtThumbnail] = React.useState<string>("");
  const [waistThumbnail, setWaistThumbnail] = React.useState<string>("");
  const [waistSize, setWaistSize] = React.useState<string | undefined>(
    profileWaistSize
  );
  const [shirtSize, setShirtSize] = React.useState<string | undefined>(
    profileShirtSize
  );

  const { productList, updateUserData } = useDatabase();

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getProductImages = async () => {
    if (size(productList) === 0) return;

    const bottoms = filter(
      productList,
      (product) => toLower(product.product_category) === "bottoms"
    );

    const tops = filter(
      productList,
      (product) => toLower(product.product_category) === "tops"
    );

    const waist_product_item = sample(bottoms);
    const shirt_product_item = sample(tops);

    if (waist_product_item && shirt_product_item) {
      setWaistThumbnail(waist_product_item.thumbnail);
      setShirtThumbnail(shirt_product_item.thumbnail);
    }
  };

  useEffect(() => {
    try {
      updateUserData({
        waistSize,
        shirtSize,
      });
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waistSize, shirtSize]);

  useEffect(() => {
    getProductImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  return (
    <motion.div
      className="edit-sizes-component"
      onClick={(e) => {
        const element = e.target as HTMLElement;
        if (includes(element.classList, "edit-sizes-component"))
          setEditSizes(false);
      }}
    >
      <div className="edit-sizes-inner">
        <button
          className="icon-close"
          onClick={(e) => {
            e.preventDefault();
            setEditSizes(false);
          }}
        >
          <FontAwesomeIcon icon={faWindowClose} color="#000000" />
        </button>

        <Slider {...settings}>
          <div>
            <div className="waist-sizes">
              <div className="label">waist</div>
              <div
                className="picture"
                style={{
                  background: `url(${waistThumbnail}) no-repeat center/contain`,
                }}
              />
              <div className="sizes">
                {map(waist_sizes, (size) => (
                  <button
                    className={`size-item ${
                      waistSize === size ? "active" : ""
                    }`}
                    key={size}
                    onClick={(e) => {
                      e.preventDefault();
                      setWaistSize(size);
                    }}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className="shirt-sizes">
              <div className="label">shirt</div>
              <div
                className="picture"
                style={{
                  background: `url(${shirtThumbnail}) no-repeat center/contain`,
                }}
              />
              <div className="sizes">
                {map(shirt_sizes, (size) => (
                  <button
                    className={`size-item ${
                      shirtSize === size ? "active" : ""
                    }`}
                    key={size}
                    onClick={(e) => {
                      e.preventDefault();
                      setShirtSize(size);
                    }}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </motion.div>
  );
};

export default EditSizes;
