import {
  faInfoCircle,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { filter, size } from "lodash";
import * as React from "react";
import { useSelectedProducts } from "../../contexts";
import { ProductCatalogueItemInterface } from "../../utilities/interfaces";
import "./styles.scss";
import { motion } from "framer-motion";

interface IProductItemProps {
  data: ProductCatalogueItemInterface;
  is_hidden: boolean;
}

const ProductItem: React.FunctionComponent<IProductItemProps> = ({
  is_hidden,
  data,
  data: { product_name, product_price, product_code, product_url },
}) => {
  const { addProduct, removeProduct, products } = useSelectedProducts();

  const isProductSelected =
    size(filter(products, (item) => item.product_code === product_code)) !== 0;

  return (
    <div
      className={`product-item ${product_name}_${product_code} ${
        is_hidden ? "is-hidden" : ""
      } `}
    >
      <div className="product-image-container">
        <motion.div
          className="product-image"
          data-id={product_code}
          style={{
            background: `url("${data.thumbnail}") no-repeat center/contain`,
          }}
        ></motion.div>
      </div>
      <div className="product-price">R{Number(product_price)}</div>
      <div className="product-info">
        <motion.a
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.98 }}
          href={product_url}
          className="info-icon product-link"
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.98 }}
          href="/"
          className="info-icon remove-product"
          onClick={(e) => {
            e.preventDefault();
            isProductSelected ? removeProduct(data) : addProduct(data);
          }}
        >
          {isProductSelected ? (
            <FontAwesomeIcon icon={faMinusCircle} />
          ) : (
            <FontAwesomeIcon icon={faPlusCircle} />
          )}
        </motion.a>
      </div>
    </div>
  );
};

export default ProductItem;
