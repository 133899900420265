import { useFormik } from "formik";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { changeDetailsSchema } from "../../utilities";
import { ChangeDetailsInterface } from "../../utilities/interfaces";
import IconLock from "../../assets/icons/icon-lock.png";
import IconEdit from "../../assets/icons/icon-edit.svg";
import "./styles.scss";
import { FormFeedBack, useAuth } from "../../contexts";
import Loader from "react-loader";
import { SubmitButton } from "../../components";
import { motion } from "framer-motion";

interface FormErrors {
  currentPassword: string;
  updateDetails: string | null;
}

const ErrorMessage = (data: { errors: any; touched: any; input: string }) => {
  const { errors, touched, input } = data;
  return (
    <>
      {errors[input] && touched[input] && (
        <span className="error">{errors[input]}</span>
      )}
    </>
  );
};
interface IEditDetailsProps {}

const EditDetails: React.FunctionComponent<IEditDetailsProps> = (props) => {
  const [formErrors, setFormErrors] = React.useState<FormErrors>({
    currentPassword: "",
    updateDetails: null,
  });
  const [successMessage, setSuccessMessage] = React.useState<string | null>(
    null
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [verfyingPassword, setVerifyingPassword] = React.useState<boolean>(
    false
  );

  const history = useHistory();
  const {
    currentUser,
    reAthenticateUser,
    updatePasswordAndDisplayName,
  } = useAuth();
  const displayName =
    currentUser?.displayName !== null && currentUser?.displayName !== undefined
      ? currentUser.displayName
      : "";

  const confirmCurrentPassword = async (password: string) => {
    setVerifyingPassword(true);
    try {
      await reAthenticateUser(password);
      setVerifyingPassword(false);
      formInk.setFieldValue("currentPasswordConfirmed", true);
    } catch {
      setVerifyingPassword(false);
      setVerifyingPassword(false);
      formInk.setFieldError(
        "currentPassword",
        "Please enter a correct password"
      );
      formInk.setFieldValue("currentPasswordConfirmed", false);
    }
  };

  const handleFormSubmit = async (values: ChangeDetailsInterface) => {
    setLoading(true);
    try {
      await updatePasswordAndDisplayName({
        password: formInk.values.password,
        displayName: formInk.values.name,
      });
      setLoading(false);
      setSuccessMessage(
        "Your details have been successfully updated please click the back button below to go your profile."
      );
      setFormErrors({ ...formErrors, updateDetails: null });
    } catch {
      console.log("error");
      setLoading(false);
      setSuccessMessage(null);
      setFormErrors({
        ...formErrors,
        updateDetails:
          "An error occured while updating your details, please try again.",
      });
    }
  };

  const initialValues: ChangeDetailsInterface = {
    name: displayName,
    currentPassword: "",
    password: "",
    confirmPassword: "",
    currentPasswordConfirmed: false,
  };

  const formInk = useFormik({
    initialValues: initialValues,
    validationSchema: changeDetailsSchema,
    onSubmit: (values: ChangeDetailsInterface) => {
      handleFormSubmit(values);
    },
  });

  return (
    <div className="edit-details">
      <form onSubmit={formInk.handleSubmit}>
        <div
          className="profile-picture"
          style={{
            background: `url('${currentUser?.photoURL}') no-repeat center/110%`,
          }}
        />
        <div className="label">
          <br />
          Edit Details
        </div>
        <div className="form-fields">
          <div className="form-group">
            <div className="form-field">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="name"
                className={
                  formInk.errors.name && formInk.touched.name
                    ? "input-error"
                    : ""
                }
                defaultValue={displayName}
                onChange={formInk.handleChange}
                onBlur={formInk.handleBlur}
              />
              <div className="icon">
                <img src={IconEdit} alt="" />
              </div>
            </div>
            <ErrorMessage
              errors={formInk.errors}
              touched={formInk.touched}
              input="name"
            />
          </div>

          <div className="label">
            <br />
            Change Password:
          </div>
          <div className="form-group">
            <div className="form-field">
              <input
                type="password"
                name="currentPassword"
                id="currentPassword"
                placeholder="***********"
                className={
                  formInk.errors.currentPassword &&
                  formInk.touched.currentPassword
                    ? "input-error"
                    : ""
                }
                onChange={formInk.handleChange}
                onBlur={(e) => {
                  formInk.handleBlur(e);
                  confirmCurrentPassword(formInk.values.currentPassword);
                }}
              />
              <div className="icon">
                <img src={IconLock} alt="" />
              </div>
              <div className="loader-icon-container">
                <Loader loaded={!verfyingPassword} color="#fff" scale={0.5} />
              </div>
            </div>
            <label className="input-label">Current password</label>
            {formInk.errors.currentPasswordConfirmed &&
              formInk.touched.currentPassword && (
                <span className="error">
                  {formInk.errors.currentPasswordConfirmed}
                </span>
              )}
          </div>
          <div className="form-group">
            <div className="form-field">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="***********"
                className={
                  formInk.errors.password && formInk.touched.password
                    ? "input-error"
                    : ""
                }
                onBlur={formInk.handleBlur}
                onChange={formInk.handleChange}
              />
              <div className="icon">
                <img src={IconLock} alt="" />
              </div>
            </div>
            <label className="input-label">new password</label>
            <ErrorMessage
              errors={formInk.errors}
              touched={formInk.touched}
              input="password"
            />
          </div>
          <div className="form-group">
            <div className="form-field">
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="***********"
                className={
                  formInk.errors.confirmPassword &&
                  formInk.touched.confirmPassword
                    ? "input-error"
                    : ""
                }
                onBlur={formInk.handleBlur}
                onChange={formInk.handleChange}
              />
              <div className="icon">
                <img src={IconLock} alt="" />
              </div>
            </div>
            <label className="input-label">confirm new password</label>
            <ErrorMessage
              errors={formInk.errors}
              touched={formInk.touched}
              input="confirmPassword"
            />
          </div>
        </div>
        <div className="links">
          <motion.div
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            className="links"
          >
            <SubmitButton
              title="SAVE CHANGES"
              cssClass="form-submit"
              disabled={!(formInk.dirty && formInk.isValid && !loading)}
              loading={loading}
              action={formInk.submitForm}
            />
          </motion.div>

          <FormFeedBack
            errorMessage={formErrors.updateDetails}
            successMessage={successMessage}
          />

          <motion.div
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            className="links"
          >
            <button
              className="go-back link-button"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              BACK
            </button>
          </motion.div>
        </div>
      </form>
    </div>
  );
};

export default EditDetails;
