import { replace } from "lodash";
import * as React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MainLogo } from "..";
import { useAuth } from "../../contexts";

import "./style.scss";

interface IMainHeaderProps {}

const MainHeader: React.FunctionComponent<IMainHeaderProps> = (props) => {
  const { signOut } = useAuth();
  const { pathname } = useLocation();

  return (
    <div className="main-header">
      <Link to="/wardrobe" className="header-top ">
        <MainLogo />
        wardrobe
      </Link>
      <div className="header-bottom">
        <div className={`header-links ${replace(pathname, /\//g, "-")}`}>
          <NavLink
            to="/wardrobe"
            exact
            className="header-link"
            activeClassName="active"
          >
            Browse wardrobe
          </NavLink>
          <NavLink
            to="/wardrobe/profile"
            exact
            className="header-link"
            activeClassName="active"
          >
            Profile
          </NavLink>
          {/*           <NavLink
            to="/wardrobe/cart"
            exact
            className="header-link"
            activeClassName="active"
          >
            Cart
          </NavLink> */}
          <a
            href="/"
            className="header-link sign-out"
            onClick={(e) => {
              e.preventDefault();
              signOut();
            }}
          >
            Logout
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
