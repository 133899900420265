import { map } from "lodash";
import * as React from "react";
import AvataTemplateListItem from "../template-list-item";

interface IAvatarTemplateListProps {
  templates: {
    label: string;
    items: Array<string>;
    z_index: number;
    top_position: number;
  };
  addToCanvas: Function;
}

const AvatarTemplateList: React.FunctionComponent<IAvatarTemplateListProps> = ({
  templates,
  addToCanvas,
}) => {
  return (
      <div className="avatar-template-list">
        {map(templates.items, (item, index) => (
          <AvataTemplateListItem
            template={item}
            template_type={templates.label}
            z_index={templates.z_index}
            top_position={templates.top_position}
            addToCanvas={addToCanvas}
            key={`template_list_${templates.label}_${index}`}
          />
        ))}
      </div>
  );
};

export default AvatarTemplateList;
