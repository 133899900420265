/* eslint-disable react-hooks/exhaustive-deps */
import { groupBy, keys, map, orderBy, size } from "lodash";
import * as React from "react";
import { ProductItem } from "..";
import { useDatabase } from "../../contexts";
import Loader from "react-loader";
import "./styles.scss";
import { motion } from "framer-motion";

interface IProductCatalogueProps {}

const ProductCatalogue: React.FunctionComponent<IProductCatalogueProps> = (
  props
) => {
  const [productCategories, setProductCategories] = React.useState<
    Array<{ index: number; key: string }>
  >([]);
  const [currentCategory, setCurrentCategory] = React.useState("bottoms");

  const database = useDatabase();
  const { productList: product_list, productListLoading: loading } = database;

  const getProductCategories = () => {
    const categoryIndexes = (key: string) => {
      return key === "bottoms" ? 0 : key === "tops" ? 1 : 2;
    };

    const grouped_products = groupBy(product_list, "product_category");

    // add index to order categories
    const category_list = map(keys(grouped_products), (key) => {
      return {
        index: categoryIndexes(key),
        key: key,
      };
    });
    setProductCategories(orderBy(category_list, ["index"], ["asc"]));
  };

  React.useEffect(() => {
    getProductCategories();
  }, [product_list]);

  React.useEffect(() => {
    if (size(product_list) === 0) database.getProductList();
  }, []);

  return (
    <div className="product-catalogue">
      <div className="catalogue-labels">
        {map(productCategories, (category) => (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.98 }}
            className={`product-label ${
              currentCategory === category.key ? "is-active" : ""
            }`}
            key={category.key}
            onClick={(e) => {
              e.preventDefault();
              setCurrentCategory(category.key);
            }}
          >
            {category.key}
          </motion.button>
        ))}
      </div>
      <div className="catalogue-list-container">
        <Loader loaded={!loading} color="#f07f1a">
          <div className="catalogue-list">
            {map(product_list, (product) => (
              <ProductItem
                data={product}
                key={product.product_code}
                is_hidden={product.product_category !== currentCategory}
              />
            ))}
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default ProductCatalogue;
