export const beards: Array<string> = [
  require("./Beard_1_C1.svg").default,
  require("./Beard_2_C1.svg").default,
  require("./Beard_3_C1.svg").default,
  require("./Beard_4_C1.svg").default,
  require("./Beard_5_C1.svg").default,
  require("./Beard_6_C1.svg").default,
  require("./Beard_7_C1.svg").default,
  require("./Beard_8_C1.svg").default,
  require("./Beard_9_C1.svg").default,
  require("./Beard_10_C1.svg").default,
  require("./Beard_11_C1.svg").default,

  require("./Beard_1_C2.svg").default,
  require("./Beard_2_C2.svg").default,
  require("./Beard_3_C2.svg").default,
  require("./Beard_4_C2.svg").default,
  require("./Beard_5_C2.svg").default,
  require("./Beard_6_C2.svg").default,
  require("./Beard_7_C2.svg").default,
  require("./Beard_8_C2.svg").default,
  require("./Beard_9_C2.svg").default,
  require("./Beard_10_C2.svg").default,
  require("./Beard_11_C2.svg").default,

  require("./Beard_1_C3.svg").default,
  require("./Beard_2_C3.svg").default,
  require("./Beard_3_C3.svg").default,
  require("./Beard_4_C3.svg").default,
  require("./Beard_5_C3.svg").default,
  require("./Beard_6_C3.svg").default,
  require("./Beard_7_C3.svg").default,
  require("./Beard_8_C3.svg").default,
  require("./Beard_9_C3.svg").default,
  require("./Beard_10_C3.svg").default,
  require("./Beard_11_C3.svg").default,

  require("./Beard_1_C4.svg").default,
  require("./Beard_2_C4.svg").default,
  require("./Beard_3_C4.svg").default,
  require("./Beard_4_C4.svg").default,
  require("./Beard_5_C4.svg").default,
  require("./Beard_6_C4.svg").default,
  require("./Beard_7_C4.svg").default,
  require("./Beard_8_C4.svg").default,
  require("./Beard_9_C4.svg").default,
  require("./Beard_10_C4.svg").default,
  require("./Beard_11_C4.svg").default,

  require("./Beard_1_C5.svg").default,
  require("./Beard_2_C5.svg").default,
  require("./Beard_3_C5.svg").default,
  require("./Beard_4_C5.svg").default,
  require("./Beard_5_C5.svg").default,
  require("./Beard_6_C5.svg").default,
  require("./Beard_7_C5.svg").default,
  require("./Beard_8_C5.svg").default,
  require("./Beard_9_C5.svg").default,
  require("./Beard_10_C5.svg").default,
  require("./Beard_11_C5.svg").default,
  "",
];
